<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-table :data="FundLogFrom" border stripe style="width: 100%" show-summary>
               <el-table-column label="序号" type="index" width="50"/>
      <el-table-column label="基金名称" prop="fundName" />
      <el-table-column label="基金编码" prop="fundCode" />
      <el-table-column label="基金类型" prop="fundType" />
      <el-table-column label="交易前金额" prop="beforeMoney" />
      <el-table-column label="本次交易金额" prop="money" />
      <el-table-column label="交易后金额" prop="afterMoney" />
      <el-table-column label="系统备注" prop="sysRemark" />
      <el-table-column label="备注" prop="remark" />
      <el-table-column label="创建时间" prop="sysCreated" />
      <el-table-column label="创建人" prop="sysCreatedBy" />
    </el-table>
  </el-dialog>
</template>
<script>
import { selectFundLogs } from "@/api/capital/fund";
export default {
  name: "FundLog",
  data() {
    return {
      dialogVisible: false,
      FundLogFrom: []
    };
  },
  methods: {
    //打开弹窗
    show(id) {
      this.dialogVisible = true;
      this.selectFundLogButton(id);
    },
    selectFundLogButton(id) {
      selectFundLogs(id).then(res => {
        this.FundLogFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script>