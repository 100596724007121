<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="updateIncomeFrom" label-width="100px" ref="updateIncomeFrom">
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="基金编码" width="120px" prop="fundCode">
            <el-input width="20px" v-model="updateIncomeFrom.fundCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="基金名称" width="120px" prop="fundName">
            <el-input width="20px" v-model="updateIncomeFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="现在投入" width="120px" prop="fundPutMoney">
            <el-input width="20px" v-model="updateIncomeFrom.fundPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="收益金额" width="120px" prop="fundIncomeMoney">
            <el-input width="20px" v-model="updateIncomeFrom.fundIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="更新收益金额" prop="money">
            <el-input-number width="200px" v-model="updateIncomeFrom.money" @change="changeMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="收益金额" width="120px" prop="newIncome">
            <el-input width="20px" v-model="updateIncomeFrom.newIncome" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="21">
        <el-col :span="21">
          <el-form-item label="备注" width="120px" prop="fundRemark">
            <el-input width="20px" v-model="updateIncomeFrom.fundRemark" ></el-input>
          </el-form-item>
        </el-col>
      
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updeteButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectFundById, updateIncomeMoney } from "@/api/capital/fund";
export default {
  name: "updateIncome",
  data() {
    return {
      dialogVisible: false,
      updateIncomeFrom: {
        newIncome: 0
      }
    };
  },
  methods: {
    //打开弹窗
    show(id) {
      this.dialogVisible = true;
      this.selectFundByButton(id);
    },
    selectFundByButton(id) {
      selectFundById(id).then(res => {
        this.updateIncomeFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    clean() {
      this.fundRemark=null;
      this.$refs["updateIncomeFrom"].resetFields();
      this.updateIncomeFrom = {};
      this.dialogVisible = false;
    },
    changeMoney() {
      this.updateIncomeFrom.newIncome = parseFloat(
        this.updateIncomeFrom.money - this.updateIncomeFrom.fundPutMoney
      ).toFixed(2);
    },
    updeteButton() {
      updateIncomeMoney(this.updateIncomeFrom).then(res => {
        if (res.code !== '200') return this.$message.error(res.message);
        this.$message.success(res.message);
        this.dialogVisible = false;
        this.$parent.getFundListButton();
        this.updateIncomeFrom = {};
      });
    }
  }
};
</script>