<template>
  <el-dialog title="编辑" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="editFundFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="存款编码" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="未投入金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundSupMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="实际金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="实际收益金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="基金名称 " width="120px">
            <el-input width="20px" v-model="editFundFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="基金编码" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="基金来源" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundSource" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="基金类型" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundType" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="基金状态 " width="120px">
            <el-input width="20px" v-model="editFundFrom.fundStatus" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="当月是否更新" width="120px">
            <el-input width="20px" v-model="editFundFrom.updateStatus" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="是否设置定投" width="120px">
            <el-select v-model="editFundFrom.isFundAuto" placeholder="请选择">
              <el-option
                v-for="item in optionAuto"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="基金备注" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundRemark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6" v-if="editFundFrom.isFundAuto==1">
          <el-form-item label="定投日期 " width="120px">
            <el-input width="20px" v-model="editFundFrom.fundAutoDate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="editFundFrom.isFundAuto==1">
          <el-form-item label="定投金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundAutoMoney"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="editFundFrom.fundType==99">
          <el-form-item label="基金开始时间" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundBeginData" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="editFundFrom.fundType==99">
          <el-form-item label="基金结束时间" width="120px">
              <el-date-picker
                v-model="editFundFrom.fundEndData"
                type="month"
                placeholder="选择月"
              ></el-date-picker>
            <!-- <el-input width="20px" v-model="editFundFrom.fundEndData"></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="editFundButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectFundById, editFund } from "@/api/capital/fund";
export default {
  name: "EditFund",
  data() {
    return {
      dialogVisible: false,
      editFundFrom: {},
      optionAuto: [
        {
          value: 1,
          label: "是"
        },
        {
          value: 2,
          label: "否"
        }
      ]
    };
  },
  methods: {
    //打开弹窗
    showEditFund(id) {
      console.log(id);
      this.dialogVisible = true;
      this.selectFundByIdButton(id);
    },
    selectFundByIdButton(id) {
      selectFundById(id).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.editFundFrom = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    editFundButton() {
      editFund(this.editFundFrom).then(res => {
        console.log(res)
        if (res.code === 500) return this.$message.error(res.message);
            this.dialogVisible = false;
           this.$parent.getFundListButton()
      },err=>{
        console.log(err.res)
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 