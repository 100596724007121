<template>
  <div class="hello">
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <!-- <el-tab-pane label="基金列表" name="first" :key="'first'"> -->
      <el-card>
        <div>
          <el-form layout="inline">
            <el-row :gutter="36">
              <el-col :md="6" :sm="8">
                <el-form-item label="基金名称">
                  <el-input placeholder style="width:60%" v-model="queryParam.fundName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="6" :sm="12">
                <el-form-item label="基金编码">
                  <el-input placeholder style="width:60%" v-model="queryParam.fundCode"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="6" :sm="24" style="width:70%">
                <el-button type="primary" @click="getFundListButton()">搜索</el-button>
                <el-button type="primary" @click="primaryRest()">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin:9px 2px">
          <el-button type="success" icon="el-icon-add" size="min" @click="addFund">新增基金</el-button>
          <el-button type="success" icon="el-icon-add" size="min" @click="bachUpdate">批量更新</el-button>
          <el-button
            type="success"
            icon="el-icon-add"
            size="min"
            @click="summaryBankMoneyButton"
          >同步卡汇总</el-button>
        </el-row>
        <!-- default-expand-all  是否需要展开 -->

        <!-- 列表区域 -->
        <el-table
          :data="funList"
          border
          style="width: 100%"
          row-key="id"
          show-summary
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="序号" type="index" width="50" />
          <el-table-column label="基金名称" prop="fundName" width="240" />
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="200"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="editFund(scope.$index, scope.row)">修改</el-button>
              <!-- <el-button type="text" v-if="scope.row.fundType==1" disabled="">增加投入</el-button> -->
              <el-button type="text" @click="adjustFundMoneyButton(scope.row,2)">卖出本金</el-button>
              <!-- v-if="scope.row.fundType!=1"  -->
              <el-button type="text" @click="adjustFundMoneyButton(scope.row,1)">增加投入</el-button>
              <!-- v-if="scope.row.fundType!=1" -->
              <!-- <el-button type="text" @click="delectFundByIdButton(scope.row)" disabled="">删除</el-button> -->
              <el-button type="text" @click="FundLogButton(scope.row)">查看流水</el-button>
              <el-button type="text" @click="updateIncomeButton(scope.row)">更新收益</el-button>
            </template>
          </el-table-column>
          <el-table-column label="已经投入金额" prop="fundPutMoney" />
          <el-table-column label="实际收益金额" prop="fundIncomeMoney" />
          <el-table-column label="收益差额" prop="diverMoney" />
          <el-table-column label="基金类型" prop="fundType" />
          <el-table-column label="基金初始投入" prop="fundInitMoney" />
          <el-table-column label="基金编码" prop="fundCode" />
          <el-table-column label="基金备注" prop="fundRemark" />
          <el-table-column label="是否设置定投" prop="isFundAuto" />
          <el-table-column label="定投日期" prop="updateStatus" />
          <el-table-column label="定投金额" prop="updateStatus" />
        </el-table>
      </el-card>
      <!-- </el-tab-pane> -->

      <!-- 基金计划表 -->
      <!-- <el-tab-pane label="基金计划列表" ref="child2" name="second" :key="'second'">
        <child2></child2>
      </el-tab-pane>-->
    </el-tabs>

    <batchUpdate ref="batchUpdateFrom" />
    <AddFund ref="addModules" />
    <EditFund ref="editModules" />
    <adjustFundMoney ref="adjustFundMoneyFrom" />
    <dealFundMoney ref="dealFundMoneyFrom" />
    <updateIncome ref="updateIncomeFrom" />
    <FundLog ref="FundLogFrom" />
  </div>
</template>
<script>
import {
  getFundList,
  delectById,
  summaryBankMoneyApi
} from "@/api/capital/fund";

import fundPlan from "./fundPlan";
import AddFund from "./modules/AddFund";
import EditFund from "./modules/EditFund";
import adjustFundMoney from "./modules/adjustFundMoney";
import dealFundMoney from "./modules/dealFundMoney";
import updateIncome from "./modules/updateIncome";
import FundLog from "./modules/FundLog";
import batchUpdate from "./modules/batchUpdate";

export default {
  name: "fundList",
  components: {
    // child2: fundPlan,
    AddFund,
    EditFund,
    adjustFundMoney,
    dealFundMoney,
    FundLog,
    updateIncome,
    batchUpdate
  },
  data() {
    return {
      activeName: "first",
      funList: [],
      updateIncomeFrom: {},
      radio: "",
      //查询条件
      queryParam: {
        fundName: "",
        fundCode: "",
        fundStatus: 2,
        updateStatus: ""
      }
    };
  },
  created() {
    this.getFundListButton();
  },
  methods: {
    parentMethod() {
      // this.$refs.child2.getFundInfoList();
    },
    addFund() {
      this.$refs.addModules.showFund(null, 1);
    },
    bachUpdate() {
      this.$refs.batchUpdateFrom.showFund(null, 1);
    },
    editFund(index, row) {
      // if ((this.id === null) | (this.id === undefined))
      //   return this.$message.error("请选择一条数据");
      this.$refs.addModules.showFund(row.id, 2);
    },
    getFundListButton() {
      getFundList(this.queryParam).then(res => {
        if (res.code === 500) {
          return this.$message.error("获取基金信息失败");
        }
        this.funList = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log(row);
      this.id = row.id;
      this.templateSelection = row;
    },
    primaryRest() {
      console.log("进来了");
      this.queryParam.fundName = "";
      this.queryParam.fundCode = "";
      this.queryParam.fundStatus = "";
      this.queryParam.updateStatus = "";
      this.getFundListButton();
    },
    adjustFundMoneyButton(row, type) {
      this.$refs.adjustFundMoneyFrom.show(row.id, type, row.refFaFundId);
    },
    dealFundMoneyButton() {
      if ((this.id === null) | (this.id === undefined))
        return this.$message.error("请选择一条数据");
      this.$refs.dealFundMoneyFrom.show(this.id);
    },
    updateIncomeButton(row) {
      // if ((this.id === null) | (this.id === undefined))
      // return this.$message.error("请选择一条数据");
      this.$refs.updateIncomeFrom.show(row.id);
    },
    FundLogButton(row) {
      this.$refs.FundLogFrom.show(row.id);
    },
    handleClickTab(tab, event) {
      console.log(tab, event);
    },
    delectFundByIdButton(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delectById(row.id).then(res => {
            console.log(res);
            this.getFundListButton();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.diverMoney > 0) {
        return "info-row";
      } else if (row.diverMoney < 0) {
        return "positive-row";
      }
      return "";
    },
    summaryBankMoneyButton() {
      summaryBankMoneyApi().then(res => {});
    }
  }
};
</script>
<style >
.el-table .info-row {
  background: #c9e5f5;
}

.el-table .positive-row {
  background: #0de711;
}
</style>
