

<template>
  <el-dialog title="调整基金池" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
    <el-form v-model="dealFundMoneyFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="基金名称" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="基金编码" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.fundCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="选择卖出类型" width="120px">
            <el-select v-model="dealFundMoneyFrom.action" placeholder="请选择" @change="changeType">
              <el-option
                v-for="item in optionAction"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="本次交易金额" width="120px">
            <el-input-number width="20px" v-model="dealFundMoneyFrom.money" @change="changeMoney" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="以投入金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.fundPutMoney" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="实际收益金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.fundIncomeMoney" disabled />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="剩余投入金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.fundSupMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="总基金池金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.noPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="交易后以投入金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.newFundPutMoney" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易后实际收益金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.newFundIncomeMoney" disabled />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="交易后剩余投入金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.newFundSupMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交易后总基金池金额" width="120px">
            <el-input width="20px" v-model="dealFundMoneyFrom.newNoPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="subbitButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { selectFundById, dealFundMoney } from "@/api/capital/fund";
export default {
  name: "dealFundMoney",
  data() {
    return {
      dialogVisible: false,
      dealFundMoneyFrom: {
        action: 1
      },
      optionAction: [
        {
          label: "增加基金池金额--扣除总基金池",
          value: 1
        },
        {
          label: "减少基金池金额--扣除总基金池",
          value: 2
        },
        {
          label: "增加基金本金金额--减少总基金池金额",
          value: 3
        },
        {
          label: "增加基金本金金额--减少基金池金额",
          value: 4
        }
      ]
    };
  },
  methods: {
    show(id) {
      this.dialogVisible = true;
      this.selectFundButton(id);
    },
    selectFundButton(id) {
      selectFundById(id).then(res => {
        console.log(res);
        this.dealFundMoneyFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    restOtherMonye() {
      this.dealFundMoneyFrom.newFundSupMoney = null;
      this.dealFundMoneyFrom.newNoPutMoney = null;
      this.dealFundMoneyFrom.newFundPutMoney = null;
      this.dealFundMoneyFrom.newFundIncomeMoney = null;
      this.dealFundMoneyFrom.money = null;
    },

    changeMoney() {
      if (
        this.dealFundMoneyFrom.action != undefined ||
        this.dealFundMoneyFrom.action != null
      ) {
        if (this.dealFundMoneyFrom.money >= 0) {
          if (this.dealFundMoneyFrom.action === 1) {
            // 增加基金池金额        正数        减少总基金池金额    action  1
            if (
              this.dealFundMoneyFrom.money > this.dealFundMoneyFrom.noPutMoney
            ) {
              this.restOtherMonye();
              return this.$message.error("总基金池没这么多金额可以转移");
            }
            //基金池金额
            this.dealFundMoneyFrom.newFundSupMoney =   parseInt(this.dealFundMoneyFrom.fundSupMoney + this.dealFundMoneyFrom.money);
            //总基金池金额
            this.dealFundMoneyFrom.newNoPutMoney = parseInt(   this.dealFundMoneyFrom.noPutMoney - this.dealFundMoneyFrom.money
            );
            //基金本金
            this.dealFundMoneyFrom.newFundPutMoney = this.dealFundMoneyFrom.fundPutMoney;
            //基金实际收益
            this.dealFundMoneyFrom.newFundIncomeMoney = this.dealFundMoneyFrom.fundIncomeMoney;
          }
          if (this.dealFundMoneyFrom.action === 2) {
            if (
              this.dealFundMoneyFrom.money > this.dealFundMoneyFrom.fundSupMoney
            ) {
              this.restOtherMonye();
              return this.$message.error("该基金池没有这么多金额转移");
            }
            this.dealFundMoneyFrom.money =  parseInt(this.dealFundMoneyFrom.money * -1);
            //  *   减少基金池金额        负数        增加总基金池金额     action  2
            console.log(this.dealFundMoneyFrom.money);
            //基金池金额
            this.dealFundMoneyFrom.newFundSupMoney =   parseInt(this.dealFundMoneyFrom.fundSupMoney +   this.dealFundMoneyFrom.money);
            //总基金池金额
            this.dealFundMoneyFrom.newNoPutMoney = parseInt(
              this.dealFundMoneyFrom.noPutMoney - this.dealFundMoneyFrom.money
            );
            //基金本金
            this.dealFundMoneyFrom.newFundPutMoney =  parseInt(this.dealFundMoneyFrom.fundPutMoney);
            //基金实际收益
            this.dealFundMoneyFrom.newFundIncomeMoney = parseInt( this.dealFundMoneyFrom.fundIncomeMoney);
          }
          if (this.dealFundMoneyFrom.action === 3) {
            // 增加基金本金金额     正数         减少总基金池金额     action  3
            if (
              this.dealFundMoneyFrom.money > this.dealFundMoneyFrom.noPutMoney
            ) {
              this.restOtherMonye();
              return this.$message.error("该基金池没有这么多金额转移");
            }

            //基金池金额
            this.dealFundMoneyFrom.newFundSupMoney = this.dealFundMoneyFrom.fundSupMoney;
            //总基金池金额
            this.dealFundMoneyFrom.newNoPutMoney = parseInt(
              this.dealFundMoneyFrom.noPutMoney - this.dealFundMoneyFrom.money
            );
            //基金本金
            this.dealFundMoneyFrom.newFundPutMoney = parseInt(
              this.dealFundMoneyFrom.fundPutMoney +
              this.dealFundMoneyFrom.mone);
            //基金实际收益
            this.dealFundMoneyFrom.newFundIncomeMoney = parseInt(
              this.dealFundMoneyFrom.fundIncomeMoney +
              this.dealFundMoneyFrom.money);
          }
          if (this.dealFundMoneyFrom.action === 4) {
              // 增加基金本金金额    正数          减少基金池基恩        action 4
            if (
              this.dealFundMoneyFrom.money > this.dealFundMoneyFrom.fundSupMoney
            ) {
              this.restOtherMonye();
              return this.$message.error("该基金池没有这么多金额转移");
            }
           //基金池金额
            this.dealFundMoneyFrom.newFundSupMoney =   parseInt(this.dealFundMoneyFrom.fundSupMoney - this.dealFundMoneyFrom.money);
            //总基金池金额
            this.dealFundMoneyFrom.newNoPutMoney = this.dealFundMoneyFrom.noPutMoney;
            //基金本金
            this.dealFundMoneyFrom.newFundPutMoney =   parseFloat((this.dealFundMoneyFrom.fundPutMoney)+parseInt(this.dealFundMoneyFrom.money)).toFixed(2);
            //基金实际收益
            this.dealFundMoneyFrom.newFundIncomeMoney =   parseFloat((this.dealFundMoneyFrom.fundIncomeMoney)+parseInt(this.dealFundMoneyFrom.money)).toFixed(2);

          }
        } else {
          this.restOtherMonye();
          return this.$message.error("该类型不能输入负数");
        }
      } else {
        this.restOtherMonye();
        return this.$message.error("请先选择类型");
      }
    },
    subbitButton() {
      dealFundMoney(this.dealFundMoneyFrom).then(res => {
        if (res.code !== '200') return this.$message.error(res.message);
        this.dialogVisible = false;
        this.$message.success(res.message);
        this.$parent.getFundListButton();
      });
    },
    changeType(row) {
      this.restOtherMonye();
    }
  }
};
</script>
<style scoped>
</style>