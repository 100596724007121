// import request from '@/utils/request'
import { getAction,getByIdAction,postAction,putAction } from '@/api/common/request'
const baseURL = 'xun/work/xunBankInfo';


const getBankInfoList = (params) => postAction(baseURL+"/list", params);

const getSelectById =(id)=>getByIdAction(baseURL+"/getSelectById",id);

const directFundMoney=(params)=>postAction(baseURL+"/directFundMoney",params);

const editFundMoney=(params)=>postAction(baseURL+"/editFundMoney",params);

const editBank=(params)=>postAction(baseURL+"/edit",params);

const selectBankInfoList=(params)=>getAction(baseURL+'/selectBankInfoList',params);

const getSelectFundList=(params)=>getAction(baseURL+'/getSelectFundList',params)

const addBank=(params)=>postAction(baseURL+'/add',params)

const selectBankLogs=(params)=>getAction(baseURL+'/selectBankLogs',params)

const shiftMoney=(params)=>postAction(baseURL+'/shiftMoney',params)

export {
  getBankInfoList,
  getSelectById,
  directFundMoney,
  editFundMoney,
  editBank,
  selectBankInfoList,
  getSelectFundList,
  addBank,
  selectBankLogs,
  shiftMoney
}
