import { getAction,getByIdAction,postAction,putAction } from '@/api/common/request'

const getEnumList =(params)=>getAction("xun/common/commonInternal/getEnumList",params);

const getFundEnumList =(params)=>getAction("xun/work/fundInternal/getEnumList",params);

const getWorkEnumList =(params)=>getAction("xun/work/workInternal/getEnumList",params);

export{
    getEnumList,
    getFundEnumList,
    getWorkEnumList
}