<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="addFundPlan" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="父基金" width="100px">
            <el-select
              v-model="bankCodeList"
              filterable
              placeholder="请选择"
              @change="AddSelectDept"
              style="width:70%"
              value-key="id"
            >
              <el-option
                v-for="item in bankOption"
                :key="item.id"
                :label="item.fundName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table :data="attendanceList" border stripe style="width: 100%" show-summary>
      <el-table-column label="序号" type="index" />
      <el-table-column label="基金编码" prop="fundCode">
        <template slot-scope="scope">
          <span>{{scope.row.fundCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="基金名称" prop="fundName">
        <template slot-scope="scope">
          <span>{{scope.row.fundName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="现投入" prop="fundPutMoney">
        <template slot-scope="scope">
          <span>{{scope.row.fundPutMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="收益金额" prop="fundIncomeMoney">
        <template slot-scope="scope">
          <span>{{scope.row.fundIncomeMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新收益金额" prop="money" width="280px">
        <template slot-scope="scope">
          <template>
            <el-input-number
              class="edit-input"
              v-model="scope.row.money"
              @change="overButton(scope.row)"
              placeholder="请输入内容"
            />
          </template>
        </template>
      </el-table-column>

      <el-table-column label="收益金额" prop="updateImcomeMoney" width="280px">
        <template slot-scope="scope">
          <template>
            <el-input-number
              class="edit-input"
              disabled
              v-model="scope.row.updateImcomeMoney"
              placeholder="请输入内容"
            />
          </template>
        </template>
      </el-table-column>
      <el-table-column label="累积收益金额" prop="accumulateMoney">
        <template slot-scope="scope">
          <span>{{scope.row.accumulateMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" width="300px">
        <template slot-scope="scope">
          <template>
            <el-input class="edit-input" v-model="scope.row.remark" placeholder="请输入内容" />
          </template>
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="AddLiveInfo()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { listByPrams, batchUpdateFund } from "@/api/capital/fund";
export default {
  name: "AddFund",
  data() {
    return {
      dialogVisible: false,
      attendanceList: [],
      addFundPlan: {},
      bankCodeList: {},
      ids: null,
      types: null,
      bankOption: [],
      querms: {
        refFaFundId: 0,
        fundStatus: 2
      },
      refFaFundId: null
    };
  },
  methods: {
    //打开弹窗
    showFund(id, type) {
      this.dialogVisible = true;
      this.ids = id;
      this.types = type;
      this.selectBankInfoListButton();
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.bankOption = [];
      this.attendanceList=[]
      this.bankCodeList={}
      this.dialogVisible = false;
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    selectBankInfoListButton() {
      this.querms.refFaFundId = 0;
      listByPrams(this.querms).then(res => {
        this.bankOption = res.result;
      });
    },
    AddSelectDept(val) {
      this.refFaFundId = val.id;
      this.listByParmButton();
    },
    listByParmButton() {
      this.querms.refFaFundId = this.refFaFundId;
      listByPrams(this.querms).then(res => {
        console.log(res);
        this.attendanceList = res.result;
      });
    },
    overButton(val) {
      val.updateImcomeMoney = (
        Number(val.money) - Number(val.fundIncomeMoney)
      ).toFixed(2);
      val.accumulateMoney = (
        Number(val.money) - Number(val.fundPutMoney)
      ).toFixed(2);
    },
    AddLiveInfo() {
      batchUpdateFund({
        batchList: this.attendanceList,
        subId: this.refFaFundId
      }).then(res => {
        this.cancel();
      });
    }
  }
};
</script>
<style scoped>
</style>