<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="addFundPlan" label-width="100px" ref="addFundPlan">
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="是否一级基金" width="160px">
            <el-checkbox v-model="ifFaType" :disabled="editShow">是</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="!ifFaType">
          <el-form-item label="父基金" width="100px" prop="refFaFundId">
            <el-select
              v-model="refFaFundData"
              filterable
              placeholder="请选择"
              @change="AddSelectDept"
              style="width:70%"
              :disabled="editShow"
              value-key="id"
            >
              <el-option
                v-for="item in bankOption"
                :key="item.id"
                :label="item.fundName"
                :value="item"
              ></el-option>
            </el-select>
            {{this.faSupMoney}}
          </el-form-item>
        </el-col>

        <el-col :span="6" v-if="!ifFaType">
          <el-form-item label="是否设置定投" width="120px">
            <el-select v-model="addFundPlan.isFundAuto" placeholder="请选择">
              <el-option
                v-for="item in optionAuto"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="基金类型" width="120px" prop="fundType">
            <el-select
              v-model="addFundPlan.fundType"
              placeholder="请选择"
              value-key="fundType"
              :disabled="editShow"
            >
              <el-option
                v-for="item in optionBelong"
                :key="item.key"
                :label="item.value"
                :value="parseInt(item.key)"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="基金类型" width="120px" v-else>
            <el-input v-model="addFundPlan.fundType" disabled />
          </el-form-item>-->
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="绑定卡 " width="120px" prop="bankId">
            <el-select v-model="addFundPlan.bankId" placeholder="请选择" :disabled="editShow">
              <el-option
                v-for="item in bankIdOptionList"
                :key="item.id"
                :label="item.subbranch"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="汇总计算卡 " width="120px" prop="sumBankId">
            <el-select v-model="addFundPlan.sumBankId" placeholder="请选择" :disabled="editShow">
              <el-option
                v-for="item in sumBankIdOptionList"
                :key="item.id"
                :label="item.subbranch"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="基金名称 " width="120px" prop="fundName">
            <el-input width="20px" v-model="addFundPlan.fundName" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="基金编码" width="120px" prop="fundCode">
            <el-input width="20px" v-model="addFundPlan.fundCode" :disabled="editShow" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="基金初始投入" width="120px" prop="fundInitMoney">
            <el-input-number width="20px" v-model="addFundPlan.fundInitMoney" />
          </el-form-item>
        </el-col>
        <!--  <el-col :span="6">
          <el-form-item label="基金现投入" width="120px">
            <el-input-number
              width="20px"
              v-model="addFundPlan.fundPutMoney"
              @change="changePreSum"
              :disabled="editShow"
            />
          </el-form-item>
        </el-col>-->
        <el-col :span="6">
          <el-form-item label="基金备注" width="120px">
            <el-input width="20px" v-model="addFundPlan.fundRemark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="!ifFaType">
          <el-form-item label="基金来源" width="120px">
            <el-input width="20px" v-model="addFundPlan.fundSource"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="6" v-if="addFundPlan.isFundAuto==1">
          <el-form-item label="定投日期 " width="120px">
            <el-input width="20px" v-model="addFundPlan.fundAutoDate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="addFundPlan.isFundAuto==1">
          <el-form-item label="定投金额" width="120px">
            <el-input-number width="20px" v-model="addFundPlan.fundAutoMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="addFundPlan.fundType==99">
          <el-form-item label="基金开始时间" width="120px">
            <el-date-picker
              v-model="addFundPlan.fundBeginData"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="addFundPlan.fundType==99">
          <el-form-item label="基金结束时间" width="120px">
            <el-date-picker
              v-model="addFundPlan.fundEndData"
              align="right"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addFundButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  addFund,
  listByPrams,
  selectFundById,
  editFund
} from "@/api/capital/fund";
import { getFundEnumList } from "@/api/commonApi/commonInternal/commonInternal";

import { getBankInfoList } from "@/api/capital/bank/bank";

export default {
  name: "AddFund",
  data() {
    return {
      dialogVisible: false,
      ifFaType: false,
      faSupMoney: null,
      addFundPlan: {
        bankId: null,
        bankCod: null,
        fundPreMoeny: 0,
        fundSupMoney: 0,
        fundPutMoney: 0,
        refFaFundId: 0,
        fundInitMoney: 0
      },
      bankOption: [],
      bankIdOptionList: [],
      sumBankIdOptionList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      bankCodeList: {
        text: null,
        noPutMoney: null
      },

      optionBelong: [],
      optionAuto: [
        {
          value: 1,
          label: "是"
        },
        {
          value: 2,
          label: "否"
        }
      ],
      //银行卡信息
      bankInfoList: [],
      querms: {
        fundStatus: 2
      },
      editShow: false,
      ids: null,
      types: null,
      refFaFundId: 0,
      refFaFundData: {}
    };
  },
  methods: {
    //打开弹窗
    showFund(id, type) {
      this.ifFaType = false;
      this.dialogVisible = true;
      this.selectBankInfoListButton();
      this.getFundEnumListButton();
      this.getBankInfoListButton();
      if (type == 2) {
        this.editShow = true;
        this.selectFundByIdButton(id);
      } else if (type == 1) {
      }
      this.ids = id;
      this.types = type;
    },
    selectBankInfoListButton() {
      this.querms.refFaFundId = 0;
      listByPrams(this.querms).then(res => {
        this.bankOption = res.result;
      });
    },
    getFundEnumListButton() {
      // optionBelong
      getFundEnumList({ enumName: "FundTypeEnum" }).then(res => {
        this.optionBelong = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.editShow = false;
      this.addFundPlan = {};
      this.refFaFundData = {};
      this.faSupMoney=0
      this.$refs["addFundPlan"].resetFields();
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
          // done();
        })
        .catch(_ => {});
    },
    addFundButton() {
      this.addFundPlan.ifFaType = this.ifFaType;
      if (this.types == 1) {
        addFund(this.addFundPlan).then(res => {
          this.cancel();
          this.$parent.getFundListButton();
        });
      }
      if (this.types == 2) {
        editFund(this.addFundPlan).then(res => {
          this.cancel();
          this.$parent.getFundListButton();
        });
      }
    },
    changePreSum() {
      this.addFundPlan.fundPreMoeny =
        this.addFundPlan.fundPutMoney + this.addFundPlan.fundSupMoney;
    },
    AddSelectDept(val) {
      this.faSupMoney = val.supMoney;
      this.addFundPlan.refFaFundId = val.id;
    },
    selectFundByIdButton(id) {
      selectFundById(id).then(res => {
        this.addFundPlan = res.result;
        if (res.result.refFaFundId == "0") {
          this.ifFaType = true;
        }
        this.$nextTick(() => {
          this.$set(this.refFaFundData, "id", res.result.refFaFundId);
        });
      });
    },

    getBankInfoListButton() {
      getBankInfoList({ bankType: 1 }).then(res => {
        this.sumBankIdOptionList = res.result.records;
      });
      getBankInfoList({ bankTypeList: [2, 5] }).then(res => {
        this.bankIdOptionList = res.result.records;
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 