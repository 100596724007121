<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="adjustFundMoneyFrom" label-width="100px" ref="adjustFundMoneyFrom">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="基金名称" width="120px" prop="fundName">
            <el-input width="20px" v-model="adjustFundMoneyFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="基金编码" width="120px" prop="fundCode">
            <el-input width="20px" v-model="adjustFundMoneyFrom.fundCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="this.types==1">
          <el-form-item label="投入金额" width="120px" prop="initPutMoney">
            <el-input width="20px" v-model="initPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="this.types==2">
          <el-form-item label="剩余金额" width="120px" prop="surplusMoney">
            <el-input width="20px" v-model="surplusMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24" v-if="isSupData==2">
        <el-col :span="8">
          <el-form-item label="机构名称" width="120px" prop="supName">
            <el-input width="20px" v-model="supFrom.supName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="流动资金" width="120px" prop="supMoney">
            <el-input width="20px" v-model="supFrom.supMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费   -->
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="收益金额" width="120px" prop="fundIncomeMoney">
            <el-input width="20px" v-model="adjustFundMoneyFrom.fundIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="本次交易金额" width="120px" prop="money">
            <el-input-number
              width="20px"
              v-model="adjustFundMoneyFrom.money"
              @change="adjustMoneyButton"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="交易后金额" width="120px" prop="afterPutMoney">
            <el-input width="20px" v-model="adjustFundMoneyFrom.afterPutMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="备注" width="120px" prop="remark">
            <el-input width="20px" v-model="adjustFundMoneyFrom.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="clean()">取 消</el-button>
      <el-button type="primary" @click="adjustFundMoneyButton" :disabled="!addSuccess">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  selectFundById,
  adjustFundMoney,
  dealFundMoney,
  getFaMoneyApi
} from "@/api/capital/fund";

import { getFundEnumList } from "@/api/commonApi/commonInternal/commonInternal";
export default {
  name: "adjustFundMoney",
  data() {
    return {
      dialogVisible: false,
      adjustFundMoneyFrom: {},
      initPutMoney: 0,
      types: null,
      title: null,
      faId: null,
      surplusMoney: null,
      optionBelong: [],
      supFrom: {
        supName: null,
        supMoney: 0
      },
      isSupData: 1,
      addSuccess: false
    };
  },
  methods: {
    selectFundByIdButton(id) {
      selectFundById(id).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.adjustFundMoneyFrom = res.result;
        this.initPutMoney = this.adjustFundMoneyFrom.fundPutMoney;
        if (this.faId != 0) {
          this.surplusMoney = this.adjustFundMoneyFrom.fundIncomeMoney;
        } else {
          this.surplusMoney = this.adjustFundMoneyFrom.surplusMoney;
        }
      });
    },
    show(id, type, faId) {
      this.dialogVisible = true;
      this.selectFundByIdButton(id);
      this.types = type;
      this.faId = faId;
      console.log("faId", faId);
      this.getFundEnumListButton();
      if (type == 1) {
        this.title = "增加投入";
        this.getFaMoneyButton(faId);
      } else {
        this.title = "卖出本金";
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.clean();
          done();
        })
        .catch(_ => {});
    },
    clean() {
      this.isSupData = 1;
      this.adjustFundMoneyFrom = {};
      this.addSuccess = false;
      this.dialogVisible = false;
    },
    adjustMoneyButton() {
      if (this.faId == 0) {
        //主基金增加投入
        if (this.types === 1) {
          this.adjustFundMoneyFrom.afterPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundIncomeMoney +
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
          this.adjustFundMoneyFrom.fundPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundPutMoney +
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
        } else {
            console.log('suMoney',this.surplusMoney,this.adjustFundMoneyFrom.money)
          if (this.adjustFundMoneyFrom.money>this.surplusMoney ) {
            this.adjustFundMoneyFrom.money = 0;
            this.adjustFundMoneyFrom.afterPutMoney = 0;
            this.$message.error("剩余金额不够");
            return;
          }

          this.adjustFundMoneyFrom.afterPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundIncomeMoney -
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
        }
      } else {
        if (this.types === 1) {
          if (this.adjustFundMoneyFrom.money > this.supFrom.supMoney) {
            this.adjustFundMoneyFrom.money = 0;
            this.adjustFundMoneyFrom.afterPutMoney = 0;
            this.$message.error("投入金额大于流动金额");
            return;
          }
          this.adjustFundMoneyFrom.afterPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundIncomeMoney +
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
          this.adjustFundMoneyFrom.fundPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundPutMoney +
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
        } else {
          if (
            this.adjustFundMoneyFrom.fundIncomeMoney <
            this.adjustFundMoneyFrom.money
          ) {
            this.adjustFundMoneyFrom.money = 0;
            this.adjustFundMoneyFrom.afterPutMoney = 0;
            this.$message.error("投入金额大于流动金额");
            return;
          }
          this.adjustFundMoneyFrom.afterPutMoney = parseFloat(
            this.adjustFundMoneyFrom.fundIncomeMoney -
              this.adjustFundMoneyFrom.money
          ).toFixed(2);
        }
      }

      this.addSuccess = true;
    },
    adjustFundMoneyButton() {
      if (this.types === 1) {
        if (this.faId == 0) {
        } else {
          this.adjustFundMoneyFrom.afterPutMoney = this.adjustFundMoneyFrom.fundPutMoney;
        }

        dealFundMoney(this.adjustFundMoneyFrom).then(res => {
          if (res.code === 500) return this.$message.error(res.message);
          this.dialogVisible = false;
          this.$parent.getFundListButton();
          this.adjustFundMoneyFrom = {};
        });
      }
      if (this.types === 2) {
        adjustFundMoney(this.adjustFundMoneyFrom).then(res => {
          if (res.code === 500) return this.$message.error(res.message);
          this.dialogVisible = false;
          this.$parent.getFundListButton();
          this.adjustFundMoneyFrom = {};
        });
      }
    },
    getFundEnumListButton() {
      getFundEnumList({ enumName: "FundTypeEnum" }).then(res => {
        this.optionBelong = res.result;
      });
    },
    getFaMoneyButton(faId) {
      if (faId == 0) {
      } else {
        getFaMoneyApi(faId).then(res => {
          this.supFrom.supName = res.result.name;
          this.supFrom.supMoney = res.result.surMoney;
          this.isSupData = 2;
        });
      }
    }
  }
};
</script>
<style scoped>
</style>

